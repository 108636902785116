<template>
  <div>
    <client :visible="visible" @edit="edit" :data="data" ref="refClient" @open_drawer_request="open_drawer_request" @close="close"/>
    <client-edit :visible="visible_edit" :data="data" ref="refClientEdit" @update_client="update_client" @close="close"/>
  </div>
</template>

<script>
import ClientEdit from "@/pages/AddClient/Client/ClientEdit";
import Client from "@/pages/AddClient/Client/Client";
export default {
  name: "ClientShowDrawer",
  components:{
    ClientEdit,
    Client
  },
  data(){
    return {
      data: null,
      visible_edit: false,
      visible: false,
    }
  },
  methods:{
    async edit(){
      this.visible = await false;
      this.visible_edit = await true;

      await this.$nextTick(); // Подождем, пока DOM обновится

      // Теперь ссылки на компоненты должны быть доступны

      if (this.$refs.refClientEdit.$refs.refFormEdit) {
        this.$refs.refClientEdit.$refs.refFormEdit.clientData = {
          uid: this.data.data.uid,
          first_name: this.data.data.first_name,
          last_name: this.data.data.last_name,
          middle_name: this.data.data.middle_name,
          // number: this.data.data.number,
          iin: this.data.data.iin,
          // address: this.data.data.address,
          email: this.data.data.email,
          notes: this.data.data.notes,
        };
        this.data.data.phone_numbers.forEach((item, index) => {
          this.$refs.refClientEdit.$refs.refFormEdit.clientData[`number_${index}`] = item.number
          this.$refs.refClientEdit.$refs.refFormEdit.clientData[`number_${index}_uid`] = item.uid
        })
      } else {
        console.error('Reference to refFormEdit is still undefined.');
      }
      console.log('this.$refs.refFormEdit', this.$refs.refClientEdit.$refs.refFormEdit)
    },
    update_client(val){
      this.data.data = val.data
      this.visible_edit = false
      this.visible = true
    },
    close(){
      this.visible = false
      this.visible_edit = false
    },
    open_drawer_request(data, edit = false){
      this.$emit('open_drawer_request', data, edit)
    }
  }

}
</script>

<style scoped>

</style>
